<template>
  <div>
    <!-- <ElementView></ElementView> -->
     <router-view></router-view>
  </div>

</template>

<script>


//import ElementView from './views/element/ElementView.vue';

export default {
  components:{
    //ElementView
  }
}
</script>