<template>
    <div style="margin: auto;margin-top:20px;width: 80%">
        <el-container>
            <el-header style="border: 1px solid #eee;background-color: rgb(238, 241, 246);font-size: 20px;line-height:60px">后台管理系统</el-header>
            <el-container>
              <el-aside width="210px" style="border: 1px solid #eee;">
                <el-menu :default-openeds="['1', '3']">
                    <el-submenu index="1">
                      <template slot="title"><i class="el-icon-message"></i>系统信息管理</template>
                      <el-menu-item-group>
                        <template slot="title">分组一</template>
                        <el-menu-item index="1-1">
                          <router-link to="/dept">部门管理</router-link>
                        </el-menu-item>
                        <el-menu-item index="1-2">
                          <router-link to="/emp">员工管理</router-link>
                        </el-menu-item>
                      </el-menu-item-group>
                      <el-menu-item-group title="分组2">
                        <el-menu-item index="1-3">选项3</el-menu-item>
                      </el-menu-item-group>
                      <el-submenu index="1-4">
                        <template slot="title">选项4</template>
                        <el-menu-item index="1-4-1">选项4-1</el-menu-item>
                      </el-submenu>
                    </el-submenu>
                    <el-submenu index="2">
                      <template slot="title"><i class="el-icon-menu"></i>导航二</template>
                      <el-menu-item-group>
                        <template slot="title">分组一</template>
                        <el-menu-item index="2-1">选项1</el-menu-item>
                        <el-menu-item index="2-2">选项2</el-menu-item>
                      </el-menu-item-group>
                      <el-menu-item-group title="分组2">
                        <el-menu-item index="2-3">选项3</el-menu-item>
                      </el-menu-item-group>
                      <el-submenu index="2-4">
                        <template slot="title">选项4</template>
                        <el-menu-item index="2-4-1">选项4-1</el-menu-item>
                      </el-submenu>
                    </el-submenu>
                    
                  </el-menu>
              </el-aside>



            

              
              
                <el-main>
                    <el-form :inline="true" :model="formInline" class="demo-form-inline">
                        <el-form-item label="姓名">
                          <el-input v-model="formInline.name" placeholder="姓名"></el-input>
                        </el-form-item>
                        <el-form-item label="性别">
                          <el-select v-model="formInline.gande" placeholder="性别">
                            <el-option label="男" value="1"></el-option>
                            <el-option label="女" value="2"></el-option>
                          </el-select>
                        </el-form-item>
                        <el-form-item>
                          <el-button type="primary" @click="onSubmit">查询</el-button>
                        </el-form-item>
                        
                        <el-form-item label="入职时间">
                            <div class="block">
                                <el-date-picker
                                  v-model="formInline.entrydata"
                                  type="daterange"
                                  range-separator="至"
                                  start-placeholder="开始日期"
                                  end-placeholder="结束日期">
                                </el-date-picker>
                              </div>
                          </el-form-item>
                        

                        
                        
                      </el-form>

                      
                      
                    <el-table :data="tabdata" border >
                        <el-table-column prop="name" label="姓名" width="180"></el-table-column>
                        <el-table-column label="图像" width="180">
                          <template slot-scope="scope">
                            <img :src="scope.row.image" alt="" style="width: 80px;height: 80px;">
                          </template>
                          
                        </el-table-column>
                        <el-table-column label="性别" width="140">
                              <template slot-scope="scope">
                                {{ scope.row.gande != 1 ? '男' : '女' }}
                                 </template>
                          
                        </el-table-column>
                        <el-table-column prop="job" label="职位" width="140"></el-table-column>
                        <el-table-column prop="entrydate" label="入职日期" width="180"></el-table-column>
                        <el-table-column prop="updatetime" label="最后操作时间" width="230"></el-table-column>
                        <el-table-column label="操作" >
                            <el-button type="primary" size="mini">编辑</el-button>
                            <el-button type="danger" size="mini">删除</el-button>
                        </el-table-column>                        
                    </el-table>

                    <br>

                    <el-pagination
                    background
                    layout="total,sizes,prev, pager, next,jumper"
                    @size-change="handSizeChange"
                    @current-change="handleCurrentChange"
                    :total="100">
                  </el-pagination>
                    
                  </el-main>
              
            </el-container>
          </el-container>
           
          
</div>
</template>

<script>
import axios from 'axios';
export default {
    data(){
        return{
            tabdata:[],
            formInline:{
                name:'',
                gande:'',
                entrydata:[]
            }
        }
    },
    methods:{
        onSubmit(){
            alert(JSON.stringify(this.formInline))
        },
        handSizeChange(){
            alert('size change')
        },
        handleCurrentChange(){
            alert('current change')
        }
    },
    mounted(){
      axios.get("https://mock.apifox.cn/m1/3128855-0-default/emp/list")
      .then((res)=>{
        this.tabdata = res.data.data;
      })
    }
}
</script>