import Vue from 'vue'
import VueRouter from 'vue-router'
import ElementView from '@/views/element/ElementView.vue'
import ElementViewCopy from '@/views/element/ElementView copy.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/dept',
    name: 'dept',
    component: ElementView
  },
  {
    path: '/emp',
    name: 'emp',
    component: ElementViewCopy
  },
  {
    path: '/',
    redirect: '/dept'
  }
]

const router = new VueRouter({
  routes
})

export default router
